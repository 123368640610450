
<ngx-spinner></ngx-spinner>
<ng-container *ngIf="fetchSession">
  <ng-container *ngIf="(isLoggedIn && emailVerified) || !isLoggedIn; else verifyEmail">
    <router-outlet></router-outlet>
    <ng-template #verifyEmail>
      We have sent a verification email. Please check and follow the steps there. If you have verified your email in another tab or window please refresh this.
      
    </ng-template>
  </ng-container>
</ng-container>
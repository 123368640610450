import { Component, ViewContainerRef, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from './shared/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    fetchSession = false;
    emailVerified = false;
    isLoggedIn = false;

    constructor(
        private router: Router,
        private spinner: NgxSpinnerService,
        private cdr: ChangeDetectorRef,
        private authService: AuthService) {
            this.spinner.show();
        this.authService.checkAuth().subscribe(data => {
            this.emailVerified = data?.emailVerified?.valueOf();
            this.isLoggedIn = this.authService.isLoggedIn;
            this.fetchSession = true;
            this.spinner.hide();
            this.cdr.detectChanges();
        })
    }

    ngOnInit() {
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



}
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app'
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService {
  private user: Observable<firebase.User>;
  public currentUser: firebase.User = null;
  public isLoggedIn = false;

  public loginState = new BehaviorSubject<boolean>(null);

  constructor(public _firebaseAuth: AngularFireAuth, public router: Router) {
    this.authState();
  }

  authState() {
    this.loginState.subscribe(data => {
      if (!data && this.currentUser != null) {
        this.logout();
      }
      if (data && this.currentUser == null) {
        // Logged in in another tab
        window.location.href = "/";
      }
    })
  }

  checkAuth() {
    this.user = this._firebaseAuth.authState;
    return this.user.pipe(
      map((user) => {
        if (user) {
          this.currentUser = user;
          this.loginState.next(true);
          this.isLoggedIn = true;
        }
        else {
          this.currentUser = null;
          this.loginState.next(false);
          this.isLoggedIn = false;
        }
        return user;
      }
    ));
  }


  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
    // return this._firebaseAuth.signInWithEmailAndPassword(email, password)

    //uncomment above firebase auth code and remove this temp code
    return new Promise(function(resolve, reject) {
      setTimeout(function() {
        resolve(true);
      }, 1000);
    });

  }

  isAuthenticated() {
    return true;
  }

  public logout() {
    this._firebaseAuth.signOut().then(res => {
      window.location.href = "/";
    })
  }

  signInWithGoogle() {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const result = this._firebaseAuth.signInWithPopup(provider);
      return result;
    } catch (error) {
      console.error("Error signing in with Google", error);
      throw error;
    }
  }
}
